<template>
    <div class="panel-header-block">
        <router-link to="/" class="header-logo-block">
            <img src="@/assets/192.png" alt="Samp Mobile Logo" draggable="false">
        </router-link>
        <div class="header-menu-block">
            <router-link to="/" class="item">{{ $t('header.main') }}</router-link>
            <router-link to="/my-servers" class="item" v-if="$store.getters.userLoggedIn">{{ $t('header.servers') }}</router-link>
            <router-link to="/login" class="item" v-else>{{ $t('header.authorization') }}</router-link>
            <router-link to="/help" class="item">{{ $t('header.help') }}</router-link>
            <router-link to="/how-to-start" class="item">{{ $t('header.how-to-start-play') }}</router-link>
            <div class="item" v-if="$store.getters.userLoggedIn" @click="exitPersonalAccount()">{{ $t('exit') }}</div>
            <locales-switcher :class="{
                'mobile-menu': mobileMenuOpened,
            }" />
            <div class="menuOpenBtn" @click="toggleMobileMenu()">
                <i class="uis uis-multiply" v-if="mobileMenuOpened"></i>
                <i class="uis uis-align-justify" v-else></i>
            </div>
        </div>
    </div>
    <div class="panel-header-sub-block">
        <a class="actionBlock" href="#">
            <span class="logoBlock discord">
                <img :src="discortSvg" alt="Discord" draggable="false">
            </span>
            <span class="text">{{ $t('header-sub.our-discord') }}</span>
        </a>
        <a class="actionBlock" href="#">
            <span class="logoBlock telegram">
                <img :src="telegramSvg" alt="Telegram" draggable="false">
            </span>
            <span class="text">{{ $t('header-sub.our-telegram') }}</span>
        </a>
        <a class="actionBlock" href="#">
            <span class="logoBlock vk">
                <img :src="vkSvg" alt="VK" draggable="false">
            </span>
            <span class="text">{{ $t('header-sub.our-vk') }}</span>
        </a>
    </div>

    <Transition name="flyFromRight">
        <div class="mobileMenu" v-if="mobileMenuOpened">
            <div class="menuItem">
                <router-link to="/" class="item active">{{ $t('header.main') }}</router-link>
            </div>
            <div class="menuItem" v-if="$store.getters.userLoggedIn">
                <router-link to="/my-servers" class="item">{{ $t('header.servers') }}</router-link>
            </div>
            <div class="menuItem" v-else>
                <router-link to="/login" class="item">{{ $t('header.authorization') }}</router-link>
            </div>
            <div class="menuItem">
                <router-link to="/help" class="item">{{ $t('header.help') }}</router-link>
            </div>
            <div class="menuItem">
                <router-link to="/how-to-start" class="item">{{ $t('header.how-to-start-play') }}</router-link>
            </div>
            <div class="menuItem" v-if="$store.getters.userLoggedIn" @click="exitPersonalAccount()">
                {{ $t('exit') }}
            </div>
        </div>
    </Transition>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

import localesSwitcher from '@/components/localesSwitcher.vue'

import discortSvg from '@/assets/discord-svgrepo-com.svg'
import telegramSvg from '@/assets/telegram-alt-svgrepo-com.svg'
import vkSvg from '@/assets/vk-svgrepo-com.svg'

const route = useRoute()
const store = useStore()

const mobileMenuOpened = ref(false)
function toggleMobileMenu(value = null) {
    if (value !== null) mobileMenuOpened.value = value
    else mobileMenuOpened.value = !mobileMenuOpened.value
}

watch(mobileMenuOpened, (value) => {
    if (value) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'auto'
})

watch(() => route.path, () => {
    if (mobileMenuOpened.value) toggleMobileMenu(false)
})

function exitPersonalAccount() {
    store.commit('unloginMe')
}
</script>

<style lang="scss" scoped>
@import 'src/styles/scss/header.scss';

.flyFromRight-enter-from,
.flyFromRight-leave-to {
    transform: translateX(100%);
}

.flyFromRight-enter-active,
.flyFromRight-leave-active {
    transition: transform 0.3s;
}

</style>