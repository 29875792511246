const supportedLocales = {
    en: {
        name: "English",
        nativeName: "English"
    },
    ru: {
        name: "Russian",
        nativeName: "Русский"
    }
}

export default supportedLocales;