import vueAPI from '@/modules/vueAPI.js'
import axios from 'axios'

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

export default {
    state: {
        userLoggedIn: false,
        row: {},
        userIsAdmin: false,
        adminRow: {},
        checkingForLogin: true
    },
    getters: {
        userLoggedIn(state) {
            return state.userLoggedIn
        },
        adminLoggedIn(state) {
            return state.userIsAdmin
        },
        getCheckingForLogin(state) {
            return state.checkingForLogin
        }
    },
    mutations: {
        async unloginMe(state) {
            state.checkingForLogin = true
            const data = await api_unloginMe()
            checkForUserLogin()
        },
        checkLogin() {
            checkForUserLogin()
        }
    },
    actions: {
    },
}

let loginCheckFirstTime = true

checkForUserLogin()
setInterval(() => {
    checkForUserLogin()
}, (1000 * 60 * 1));

function checkForUserLogin() {
    vueAPI.onMounted(async (thisVue) => {
        const data = await api_checkLoginUser()
        // console.log(data)
        if (data.response == 0) {
            let loginStateChanged = false
            if (thisVue.$store.state.loginData.userLoggedIn) loginStateChanged = true
            thisVue.$store.state.loginData.userLoggedIn = false
            if (thisVue.$store.state.loginData.userIsAdmin) loginStateChanged = true
            thisVue.$store.state.loginData.userIsAdmin = false
            thisVue.$store.state.loginData.row = {}
            thisVue.$store.state.loginData.adminRow = {}

            if (!loginCheckFirstTime && loginStateChanged) {
                window.location.reload()
            }
        } else {
            let loginStateChanged = false

            thisVue.$store.state.loginData.userLoggedIn = true
            thisVue.$store.state.loginData.row = data.data.row
            if (thisVue.$store.state.loginData.userIsAdmin != data.data.is_admin) loginStateChanged = true
            thisVue.$store.state.loginData.userIsAdmin = data.data.is_admin

            if (data.data.is_admin) {
                thisVue.$store.state.loginData.adminRow = data.data.admin_row
            } else {
                thisVue.$store.state.loginData.adminRow = {}
            }

            if (!loginCheckFirstTime && loginStateChanged) {
                window.location.reload()
            }
        }

        loginCheckFirstTime = false
        thisVue.$store.state.loginData.checkingForLogin = false
    })
}

async function api_checkLoginUser() {
    const response = await axios.get('/api/login_check.php')
    return response.data
}

async function api_unloginMe() {
    const response = await axios.get('/api/unlogin.php')
    return response.data
}