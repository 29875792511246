<template>
    <router-view v-if="!$store.getters.getCheckingForLogin" />
</template>

<script>
import $ from "jquery"
window.$ = $
import Swal from 'sweetalert2-neutral'
window.Swal = Swal
import device from 'current-device'
window.device = device

import vueAPI from "./modules/vueAPI.js"

import Trans from '@/plugins/Translation.js'

window.unregSW = function() {
    vueAPI.onServiceWorkerInstalled((serviceWorker) => {
        serviceWorker.unregister().then(() => {
            console.log('Service worker unregistered')
            location.reload()
        })
    })
}

export default {
    name: 'App',
    mounted() {
        vueAPI._mountedCallback(this)
    },
    beforeMount() {
        if (localStorage.getItem('lang')) {
            Trans.changeLocale(localStorage.getItem('lang'))
        }
    },
    watch: {
        '$i18n.locale': function(newValue) {
            this.setLocalStorageLanguage(newValue)
        },
        '$store.getters.getLoginState': function(newValue) {
            if (newValue == true) return
            console.log(`Login state changed to ${newValue}`)
            console.log(this.$route.meta.requiresAuth)

            if (this.$route.meta.requiresAuth) {
                this.$store.state.loginData.unloginPosition = window.location.pathname
                this.$router.replace('/login')
            }
        }
    },
    methods: {
        setLocalStorageLanguage(lang = false) {
            if (lang) {
                localStorage.setItem('lang', lang)
            } else {
                localStorage.setItem('lang', this.$i18n.locale)
            }
        }
    },
}
</script>

<style lang="scss">
@import 'src/styles/scss/main.scss';
</style>
