<template>
    <div class="mainWrapperBlock">
        <router-view/>
    </div>
</template>

<script>
export default {
    name: 'panelMain',
    data() {
        return {}
    }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/scss/panelMain.scss';
</style>