import vueAPI from '@/modules/vueAPI.js'
import { createStore } from 'vuex'

import loginData from './modules/loginData.js'

export default createStore({
    state: {
        dateNow: new Date()
    },
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        loginData
    }
})

setInterval(() => {
    vueAPI.onMounted((thisVue) => {
        thisVue.$store.state.dateNow = new Date()
    })
}, (1000 * 10));