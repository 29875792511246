import { createRouter, createWebHistory } from 'vue-router'
import vueAPI from '@/modules/vueAPI'
import mainPage from '@/views/mainPage.vue'

const routes = [
    {
        path: '/',
        name: 'mainPage',
        component: mainPage,
        children: [
            {
                path: '',
                name: 'home',
                component: () => import(/* webpackChunkName: "homePage" */ '@/components/homePage.vue')
            },
            {
                path: 'login',
                name: 'login',
                meta: {
                    requiresGuest: true
                },
                component: () => import(/* webpackChunkName: "authPage" */ '@/components/loginPage.vue')
            },
            {
                path: 'register',
                name: 'register',
                meta: {
                    requiresGuest: true
                },
                component: () => import(/* webpackChunkName: "authPage" */ '@/components/registerPage.vue')
            },
            {
                path: 'help',
                name: 'help',
                component: () => import(/* webpackChunkName: "helpPage" */ '@/components/helpPage.vue')
            },
            {
                path: 'how-to-start',
                name: 'howToStart',
                component: () => import(/* webpackChunkName: "howToStartPage" */ '@/components/howToStartPage.vue')
            },
            {
                path: 'my-servers',
                name: 'myServers',
                meta: {
                    requiresAuth: true
                },
                component: () => import(/* webpackChunkName: "myServersPage" */ '@/components/myServersPage.vue')
            },
            {
                path: 'my-servers/add',
                name: 'addServer',
                meta: {
                    requiresAuth: true
                },
                component: () => import(/* webpackChunkName: "serverCreatePage" */ '@/components/serverCreatePage.vue')
            },
            {
                path: 'my-servers/edit/:id(\\d+)',
                name: 'editServer',
                meta: {
                    requiresAuth: true
                },
                component: () => import(/* webpackChunkName: "serverEditPage" */ '@/components/serverEditPage.vue')
            },
            {
                path: 'my-servers/pay/:id(\\d+)',
                name: 'payServer',
                meta: {
                    requiresAuth: true
                },
                component: () => import(/* webpackChunkName: "serverPayPage" */ '@/components/serverPayPage.vue')
            }
        ]
    }
    // {
    //     path: '/about',
    //     name: 'about',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    // }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
            }
        } else {
            return { top: 0 }
        }
    }
})

router.beforeEach(async (to) => {
    const thisVue = await vueAPI.asyncOnMounted()
    if (to.meta.requiresAuth || to.meta.requiresAdmin || to.meta.requiresGuest) {
        if (thisVue.$store.getters.getCheckingForLogin) {
            await new Promise((resolve, reject) => {
                let unwatchThis = thisVue.$watch('$store.getters.getCheckingForLogin', () => {
                    unwatchThis()
                    resolve()
                })
            })
        }

        if (to.meta.requiresAuth) {
            if (!thisVue.$store.getters.userLoggedIn) return{ path: '/login' }
        } else if (to.meta.requiresAdmin) {
            if (!thisVue.$store.getters.userLoggedIn) return { path: '/login' }
            if (!thisVue.$store.getters.adminLoggedIn) return { path: '/login' }
        } else if (to.meta.requiresGuest) {
            if (thisVue.$store.getters.userLoggedIn) return { path: '/' }
        }
    }

    return true
})

export default router
