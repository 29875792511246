<template>
    <span class="selector_wrapper" :class="class">
        <div class="true_language">
            <template v-for="(thisLocale, thisLocaleKey) in locales" :key="thisLocaleKey">
                <span v-if="thisLocaleKey == $i18n.locale">
                    <img v-if="localesIcons.hasOwnProperty(thisLocaleKey)" :src="localesIcons[thisLocaleKey]" :alt="thisLocale.nativeName" />
                    {{ thisLocale.nativeName }}
                </span>
            </template>
            <i class="uil uil-angle-down"></i>
        </div>
        <select @change="switchLocaleEvent">
            <option v-for="(thisLocale, thisLocaleKey) in locales" :key="thisLocaleKey"
            :value="thisLocaleKey"
            :selected="thisLocaleKey == $i18n.locale">{{ thisLocale.nativeName }}</option>
        </select>
    </span>
</template>

<script>
import Trans from '@/plugins/Translation.js'

import ruIcon from '@/assets/lang/ru.svg'
import enIcon from '@/assets/lang/en.svg'

export default {
    props: {
        class: {
            type: [Object, String],
            required: false,
            default: ''
        }
    },
    data() {
        return {
            locales: Trans.supportedLocales,
            localesIcons: {
                ru: ruIcon,
                en: enIcon
            }
        }
    },
    methods: {
        switchLocaleEvent($event) {
            if (!$event.target || !$event.target.value) return;

            this.switchLocale($event.target.value);
        },
        switchLocale(locale) {
            if (this.$i18n.locale !== locale) {
                Trans.changeLocale(locale).then(() => {
                    window.scrollTo({top: 0, behavior: 'smooth'});
                }).catch((err) => {
                    console.warn(err)
                })
            }
        }
    },
}
</script>

<style lang="scss" scoped>
    @import 'src/styles/scss/localesSwitcher.scss';
</style>