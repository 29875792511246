let vueAPI = {
    _thisSW: null,
    _thisVueObj: null,
    _mounted: false,
    _mountedCallbacks: [],
    _swCallbacks: [],
    onMounted(callback) {
        if (typeof callback === 'function') {
            if (this._mounted) {
                callback(this._thisVueObj);
            } else {
                this._mountedCallbacks.push(callback);
            }
        }
    },
    asyncOnMounted() {
        return new Promise((resolve, reject) => {
            this.onMounted(resolve);
        });
    },
    onServiceWorkerInstalled(callback) {
        if (typeof callback === 'function') {
            if (this._thisSW) {
                callback(this._thisSW);
            } else {
                this._swCallbacks.push(callback);
            }
        }
    },
    _mountedCallback(thisVue) {
        this._mounted = true;
        this._thisVueObj = thisVue;
        this._mountedCallbacks.forEach(callback => callback(thisVue));
        this._mountedCallbacks = [];
    },
    _swCallback(thisSW) {
        this._thisSW = thisSW;
        this._swCallbacks.forEach(callback => callback(thisSW));
        this._swCallbacks = [];
    }
}

export default vueAPI;