<template>
    <panel-header></panel-header>
    <panel-main></panel-main>
</template>

<script>
import panelHeaderVue from '@/views/panelHeader.vue'
import panelMainVue from '@/views/panelMain.vue'

export default {
    name: 'mainPage',
    components: {
        panelHeader: panelHeaderVue,
        panelMain: panelMainVue
    },
    data() {
        return {}
    },
    mounted() {
        // if (!this.$store.state.loginData.adminLoggedIn) {
        //     this.$router.push('/login');
        // }
    }
}
</script>

<style lang="scss">
</style>