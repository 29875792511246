export default {
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск"])},
  "exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выход"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])},
  "download-game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать игру"])},
  "header": {
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])},
    "authorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторизация"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помощь"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация"])},
    "servers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервера"])},
    "how-to-start-play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как начать играть"])}
  },
  "header-sub": {
    "our-discord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наш Discord"])},
    "our-telegram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наш Telegram"])},
    "our-vk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наш VK"])}
  },
  "my-servers": {
    "add-vkladka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить вкладку Mobile"])},
    "end-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания"])},
    "added-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата добавления"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплатить"])},
    "add-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить сервер"])},
    "ip-change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Смена IP"])},
    "server-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата сервера"])},
    "write-new-ip:port": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите новый IP : PORT"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить"])},
    "server-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название сервера"])},
    "ip:port": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP : PORT"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена"])},
    "select-payment-gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите платежную систему"])}
  },
  "auth": {
    "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
    "create-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать аккаунт"])},
    "do-you-have-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас уже есть аккаунт?"])},
    "you-dont-have-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас нет аккаунта?"])},
    "enter-your-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите ваш email"])},
    "enter-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите пароль"])},
    "repeat-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторите пароль"])}
  },
  "about-us": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О samp-mobile.com"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samp-mobile.com является эксклюзивным поставщиком списков игровых серверов для SA-MP. Эти списки отображаются в списке размещенных серверов, отдельно от обычных общедоступных серверов. Появление на отдельном Список может увеличить уязвимость сервера для потенциальных игроков. Использование этой услуги не является обязательным и не является требуется для запуска сервера SA-MP."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание учетной записи"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы запросить добавление вашего сервера в список серверов, вам потребуется учетная запись Game-mp. Пожалуйста, ознакомьтесь с условиями обслуживания и следуйте ссылка «Создать учетную запись». После того, как вы предоставили необходимую информацию, войдите в свою учетную запись Подробная информация будет отправлена на ваш адрес электронной почты.Дополнительные инструкции по добавлению и обновлению объявлений можно найти в разделе панели управления этого веб-сайта."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддержка"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все вопросы поддержки следует адресовать support", "@", "samp-mobile.com. Если у вас есть активная учетная запись Game-mp, пожалуйста, укажите адрес электронной почты вашей учетной записи. Если ваш запрос в службу поддержки касается существующего объявления, пожалуйста, укажите IP-адрес и порт сервера или список #, найденный в панели управления."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условия"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все серверы, перечисленные в этой службе, также должны соответствовать Соглашению об услугах SA-MP.Списки, предоставляемые этой службой, относятся к онлайн-видеоиграм и предоставляются в развлекательных целях. Пока Будут приложены все усилия для поддержания списков в Интернете, возмещение или компенсация не гарантируются в случае обслуживания недоступность.Используя эту услугу, вы соглашаетесь:"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["а) Что вы старше 18 лет."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["б) Серверы, указанные в вашей учетной записи, принадлежат вам или арендованы вами, или вы арендуете IP-адрес, связанный с листингом."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c) Ваша учетная запись и любые связанные с ней объявления могут быть приостановлены по любой причине, не ограничиваясь условиями, перечисленными здесь."])}
  ],
  "how-to-start-play": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы начать играть на нашем проекте - необходимо скачать и установить лаунчер и запустить его, всю загрузку и установку игры лаунчер сделает сам"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачай и установи лаунчер, дождись загрузки файлов и начни играть!"])}
  ]
}